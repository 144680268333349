import { LONG_DAYS, LONG_MONTHS } from "data";

export const fullDateTime = (date?: Date | string) => {
  if (!date) {
    return ``;
  }

  const convertToDate = new Date(date);
  const day = String(convertToDate.getDate()).padStart(2, '0');
  const month = String(convertToDate.getMonth() + 1).padStart(2, '0');
  const year = convertToDate.getFullYear() + 543;
  const hour = String(convertToDate.getHours()).padStart(2, '0');
  const minutes = String(convertToDate.getMinutes()).padStart(2, '0');
  const seconds = String(convertToDate.getSeconds()).padStart(2, '0');

  return `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`;
};

export const formatDateTh = (date?: Date | string) => {
  if (!date) {
    return '';
  }

  const getDate = new Date(date);
  const day = String(getDate.getDate()).padStart(2, '0');
  const month = String(getDate.getMonth() + 1).padStart(2, '0');
  const year = getDate.getFullYear() + 543;
  const formatDate = `${day}/${month}/${year}`;

  return formatDate;
};

export const formatDateToInput = (date: Date | string) => {
  const getDate = new Date(date);
  const formatDate = `${getDate.getDate()}-${getDate.getMonth() + 1}-${getDate.getFullYear() + 543}`;

  return formatDate;
};

export const formatTimeToInput = (date?: Date | string) => {
  if (!date) {
    return ``;
  }

  const getDate = new Date(date);
  const hour = String(getDate.getHours()).padStart(2, '0');
  const minutes = String(getDate.getMinutes()).padStart(2, '0');
  const formatDate = `${hour}.${minutes}`;

  return formatDate;
};

export const getMonthYearBC = (value: string | Date) => {
  const date = new Date(value);

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear() < 2500 ? date.getFullYear() + 543 : date.getFullYear();

  return `${month}/${year} `;
};

export const getFormatDateBC = (value: string | Date | undefined) => {
  if (!value) {
    return ``;
  }

  const date = new Date(value);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear() < 2500 ? date.getFullYear() + 543 : date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const getYearBC = (value: string | Date) => {
  const date = new Date(value);
  const year = date.getFullYear() < 2500 ? date.getFullYear() + 543 : date.getFullYear();

  return `${year} `;
};

export const thaiFormatDate = (value: string | Date) => {
  if (!value) {
    return;
  }

  const date = new Date(value);

  const day = date.getDate();
  const month = LONG_MONTHS.find((_, index) => index === date.getMonth());
  const year = date.getFullYear() + 543;

  return `${day} ${month} ${year}`;
};

export const getTime = (value: string | Date | undefined) => {
  if (!value) {
    return ``;
  }

  const date = new Date(value);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

export const thaiFullFormatData = (value: string | Date) => {
  if (!value) {
    return;
  }

  const date = new Date(value);

  const day = LONG_DAYS[date.getDay()].label;
  const month = LONG_MONTHS[date.getMonth()];
  const year = date.getFullYear() + 543;

  return `${day} ${date.getDate()} ${month} พ.ศ.${year}`;
}

export const yearToTHFormat = (year: number | null) => {
  if (!year) {
    return '';
  }

  return year + 543;
}

export const dashboardThaiDate = (value: Date) => {
  return new Intl.DateTimeFormat('th-TH', {
    dateStyle: 'full',
  }).format(value);
}