import { Card, Layout, Modal, Status, StatusType, Table, VideoBrowse } from "components";
import { MeetingRecordDetailModel, MeetingRecordListModel, Pagination } from "models";
import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BsRecordCircle } from "react-icons/bs";
import { FaAngleLeft, FaPause, FaPauseCircle, FaPlay, FaStopCircle, FaSyncAlt, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { formatDateTh, formatTimeToInput, getTime, showModalConfirmAsync, manageCacheVideoAsync, startCacheVideoAsync, stopCacheVideoAsync, clearCacheVideoAsync } from "utils";
import { s0202 as service, recordService } from 'services';
import { HttpStatusCode } from "axios";
import toast from "utils/toast";
import { StatusMeeting } from "constant";
import Hls from "hls.js";
import { v4 as uuidv4 } from 'uuid';
import { useUser, useHls } from "hooks";
import { MdLayersClear } from "react-icons/md";

type Loader = { meetingHeaderDetail: MeetingRecordListModel };

type MeetingRecordDetailContext = {
  page: number;
  size: number;
  meetingRecordDetail: Pagination<MeetingRecordDetailModel>;
  setMeetingRecordDetail: Dispatch<SetStateAction<Pagination<MeetingRecordDetailModel>>>;
  searchAsync: Function;
  onChangePageSize: (size: number, page: number) => void
  onUploadAsync: Function;
  meetingDetail: MeetingRecordListModel;
};

interface RecordModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: () => void;
};

interface StreamData {
  streamState: string;
  status: string;
  bitrate: string;
  duration: string;
  cache: string;
};

const Context = createContext({} as MeetingRecordDetailContext);

export default function S0202Detail() {
  const { id } = useParams();
  const { user } = useUser();
  const [meetingRecordDetail, setMeetingRecordDetail] =
    useState<Pagination<MeetingRecordDetailModel>>({} as Pagination<MeetingRecordDetailModel>);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [meetingDetail, setMeetingDetail] = useState({} as MeetingRecordListModel);
  const [mdSize, setMdSize] = useState<boolean>(false);

  useEffect(() => {
    meetingDetailAsync();
    searchAsync(page, size, id!);

    window.onresize = function (event) {
      const widthScreen = (event.target as Window).innerWidth;

      if (widthScreen < 992) {
        setMdSize(true);
      } else {
        setMdSize(false);
      }
    };
  }, []);

  const searchAsync = useCallback(async (page: number, size: number, id: string) => {
    const { data, status } = await service.getSnMeetingRecordDetailListAsync(page, size, id);

    if (status === HttpStatusCode.Ok) {
      setMeetingRecordDetail(data);
    }
  }, [page, size]);

  const meetingDetailAsync = async () => {
    const result = await service.getMeetingDetailAsync(id!);

    if (result.status === HttpStatusCode.Ok) {
      setMeetingDetail(result.data);
    }
  };

  const onUploadAsync = async (data: MeetingRecordDetailModel, file: File) => {
    data.video = file;
    await service.uploadManualVideoAsync(id!, data.id, file);

    toast.success("นำเข้าวิดีโอสำเร็จ");

    const getDetail = await service.getSnMeetingRecordDetailListAsync(page, size, id!);

    if (getDetail.status === HttpStatusCode.Ok) {
      setMeetingRecordDetail(getDetail.data);
    }
  };

  const onChangePageSize = (size: number, page: number) => {
    setPage(page);
    setSize(size);

    searchAsync(page, size, id!);
  };

  const contextValue = useMemo(() => {
    return {
      meetingRecordDetail,
      page,
      size,
      setMeetingRecordDetail,
      searchAsync,
      onChangePageSize,
      onUploadAsync,
      meetingDetail,
    }
  }, [meetingRecordDetail, page, size, setMeetingRecordDetail, searchAsync, onChangePageSize, onUploadAsync, meetingDetail]);

  const onUploadFullAsync = async (id: string, file: File | Blob) => {
    await service.uploadFullVideoManualAsync(id!, file as File);

    toast.success("อัพโหลดวิดิโอสำเร็จ");
  };

  return (
    <Context.Provider value={contextValue}>
      <Layout title="บันทึกข้อมูลภาพและเสียงการประชุม" className="s0202-detail">
        <HeaderButton />
        <Row>
          <Col lg={7} md={12}>
            <Detail />
          </Col>
          <Col lg={5} md={12}>
            {!mdSize ? <Time /> : null}
          </Col>
          <Col lg={7} md={12} className="mt-3">
            <Video />
          </Col>
          <Col lg={5} md={12} className="mt-3">
           <div className="d-flex flex-column justify-content-between h-100">
           <div className="h-50">
              <Controller />
              {mdSize ? <Time /> : null}
           </div>
            <div>
              <VideoBrowse
                    className="mt-3 w-100"
                    confirm={meetingDetail.videoFileId ? true : false}
                    onChange={(val) => onUploadFullAsync(id!, val)}
                    disabled={!(user.isAdmin || user.isCommander) || meetingDetail.status === StatusMeeting.RECORD} />
                    <Button
                    variant="outline-primary"
                    className="mt-3 w-100"
                    onClick={() => searchAsync(page, size, id!)}>
                    <FaSyncAlt className="me-2" />รีเฟรซข้อมูลในตาราง
                  </Button>
            </div>
           </div>
          </Col>
        </Row>
        <DataTable
          onChange={() => searchAsync(page, size, id!)} />
      </Layout>
    </Context.Provider>
  );
}

function HeaderButton() {
  const navigate = useNavigate();

  const onBack = () => {
    navigate("/s0202");
  };

  return (
    <div className="my-3">
      <Button
        variant="outline-primary"
        type="button"
        onClick={onBack}>
        <FaAngleLeft className="me-2" />ย้อนกลับ
      </Button>
    </div>
  );
}

function Detail() {
  const { meetingHeaderDetail } = useLoaderData() as Loader;

  return (
    <Card title="รายละเอียดการบันทึกสัญญาณภาพและเสียง">
      <Row>
        <Col lg={6} md={12}>
          <p>ประเภทการประชุม<span className="mx-2">:</span>{meetingHeaderDetail.meetingTypeName}</p>
        </Col>
        <Col lg={2} md={12}>
          <p>ครั้งที่<span className="mx-2">:</span>ครั้งที่ {meetingHeaderDetail.time}</p>
        </Col>
        <Col lg={4} md={12}>
          <p>สมัย<span className="mx-2">:</span>{meetingHeaderDetail.meetingPeriodName}</p>
        </Col>
        <Col lg={6} md={12}>
          <p>ปี<span className="mx-2">:</span>{meetingHeaderDetail.year + 543}</p>
        </Col>
        <Col lg={6} md={12}>
          <p>ห้องประชุม<span className="mx-2">:</span>{meetingHeaderDetail.meetingRoomName}</p>
        </Col>
        <Col xs={12}>
          <p>
            วันที่ประชุม
            <span className="mx-2">:</span>
            {formatDateTh(meetingHeaderDetail.startDate)}
            <span className="ms-2">{formatTimeToInput(meetingHeaderDetail.startDate)} - {formatTimeToInput(meetingHeaderDetail.endTime)}</span>
          </p>
        </Col>
      </Row>
    </Card>
  );
}

function Time() {
  const [dateCurrent, setDateState] = useState<Date>(new Date());

  useEffect(() => {
    const timer = setInterval(() => setDateState(new Date()), 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Card title="เวลาปัจจุบัน" className="text-primary time-card">
      <div className="time">
        {dateCurrent.toLocaleString('th-TH', {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        })}
      </div>
    </Card>
  );
}

function Video() {
  const { meetingDetail } = useContext(Context);
  const { hlsRef } = useHls();
  const [streamingUrl, setStreamingUrl] = useState<string>();
  const [streamingUrlOrigin, setStreamingUrlOrigin] = useState<string>();
  const streamingUrlInput = process.env.REACT_APP_STREAMING_URL_INPUT;
  const streamingUrlOutput = process.env.REACT_APP_STREAMING_URL_OUTPUT;
  const streamingName = uuidv4();

  useEffect(() => {
    if (meetingDetail.id) {
      getStreamingUrlAsync();
    }
  }, [meetingDetail]);

  useEffect(() => {
    if (streamingUrlOrigin) {
      reStream(streamingUrlOrigin);
    }
  }, [streamingUrlOrigin]);

  useEffect(() => {
    if (streamingUrl) {
      startHls();
    }
  }, [streamingUrl]);

  const getStreamingUrlAsync = async () => {
    const res = await service.getStreamingUrlAsync(meetingDetail.smMeetingRoomId);

    if (res.status === HttpStatusCode.Ok && res.data) {
      setStreamingUrlOrigin(res.data);
    }
  };

  const reStream = (url: string) => {
    console.log(url);
    if (!url) {
      toast.error('ไม่พบสัญญาณภาพ');

      return;
    }

    recordService.reStreamAsync(url, `${streamingUrlInput}/${streamingName}`);

    setStreamingUrl(`${streamingUrlOutput}/${streamingName}.m3u8`);
  };

  const startHls = () => {
    if (!streamingUrl) {
      return;
    }

    const video = document.getElementById("video-streaming") as HTMLMediaElement;

    if (Hls.isSupported()) {
      const config = new Hls();
      hlsRef.current = config;

      config.loadSource(streamingUrl);
      config.attachMedia(video);
      config.on(Hls.Events.ERROR, (_, data) => {
        // for see error on console
        console.error(data);

        setTimeout(() => startHls(), 10000);
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = streamingUrl;
    }
  };

  return ( 
      <video
      id="video-streaming"
      autoPlay={true}
      className="h-100 w-100" />  
  );
};

function Controller() {
  const { meetingDetail } = useContext(Context);
  const delay = 30;
  const [recordButtonDelay, setRecordButtonDelay] = useState<number>();
  const [stopButtonDelay, setStopButtonDelay] = useState<number>();
  const [showStartModal, setShowStartModal] = useState<boolean>(false);
  const [showPauseModal, setShowPauseModal] = useState<boolean>(false);
  const [showStopModal, setShowStopModal] = useState<boolean>(false);
  const [currentStatus, setCurrentStatus] = useState<StatusMeeting>();

  useEffect(() => {
    setCurrentStatus(meetingDetail.status as StatusMeeting);
  }, [meetingDetail]);

  const countdownCanStop = () => {
    setStopButtonDelay(delay);

    setInterval(() => {
      setStopButtonDelay(val => {
        if (val && val >= 0) {
          return val - 1;
        }
      });
    }, 1000);
  };

  const countdownCanRecord = () => {
    setRecordButtonDelay(delay);

    setInterval(() => {
      setRecordButtonDelay(val => {
        if (val && val >= 0) {
          return val - 1;
        }
      });
    }, 1000);
  };

  const onStartSubmit = () => {
    setCurrentStatus(StatusMeeting.RECORD);

    toast.info("กำลังบันทึกข้อมูลภาพและเสียงการประชุม");

    setShowStartModal(false);

    countdownCanStop();
  };

  const onPauseSubmit = () => {
    setCurrentStatus(StatusMeeting.PAUSE_RECORD);

    toast.info("กำลังหยุดบันทึกข้อมูลภาพและเสียงการประชุมชั่วคราว");

    setShowPauseModal(false);

    countdownCanRecord();
  };

  const onStopSubmit = () => {
    setCurrentStatus(StatusMeeting.RECORDED);

    toast.info("จบการบันทึกข้อมูลภาพและเสียงการประชุม");

    setShowStopModal(false);
  };

  const disabledStart = useMemo(() => {
    return (currentStatus !== StatusMeeting.CONFIRM && currentStatus !== StatusMeeting.PAUSE_RECORD) ||
      (!!recordButtonDelay && recordButtonDelay >= 0);
  }, [currentStatus]);

  const disabledPauseAndStop = useMemo(() => {
    return (currentStatus === StatusMeeting.DRAFT || currentStatus === StatusMeeting.CONFIRM || currentStatus == StatusMeeting.RECORDED) ||
      (!!stopButtonDelay && stopButtonDelay >= 0);
  }, [currentStatus]);

  return (
    <>
      <div className="controller d-flex gap-2 h-50">
        <Button
          variant="outline-primary"
          className="align-items-center"
          onClick={() => setShowStartModal(true)}
          disabled={disabledStart}>
          {recordButtonDelay && recordButtonDelay >= 0 ? recordButtonDelay : <><BsRecordCircle className="mt-2 fs-2" />บันทึก</>}
        </Button>
        <Button
          variant="outline-primary"
          className="align-items-center"
          onClick={() => setShowPauseModal(true)}
          disabled={disabledPauseAndStop}>
          {stopButtonDelay && stopButtonDelay >= 0 ? stopButtonDelay : <><FaPauseCircle className="mt-2 fs-2" />
            <span className="fs-4">หยุดบันทึกชั่วคราว</span>
          </>}
        </Button>
        <Button
          variant="outline-primary"
          className="align-items-center"
          onClick={() => setShowStopModal(true)}
          disabled={disabledPauseAndStop}>
          {stopButtonDelay && stopButtonDelay >= 0 ? stopButtonDelay : <><FaStopCircle className="mt-2 fs-2" />จบการประชุม</>}
        </Button>
      </div>
      <StartRecord
        onSubmit={onStartSubmit}
        onHide={() => setShowStartModal(false)}
        show={showStartModal} />
      <PauseRecord
        onSubmit={onPauseSubmit}
        onHide={() => setShowPauseModal(false)}
        show={showPauseModal} />
      <StopRecord
        onSubmit={onStopSubmit}
        onHide={() => setShowStopModal(false)}
        show={showStopModal} />
    </>
  );
}

function DataTable(props: { onChange: () => void }) {
  const { meetingRecordDetail, onChangePageSize, onUploadAsync, size, page } = useContext(Context);
  const { id } = useParams();
  const { user } = useUser();

  const onRemoveAsync = async (rankId: string) => {
    if (await showModalConfirmAsync('ยืนยันการลบไฟล์')) {
      const { status } = await service.removeFileAsync(id!, rankId);

      if (status === HttpStatusCode.Accepted) {
        toast.success('ลบไฟล์สำเร็จ');

        props.onChange();
      }
    }
  };

  return (
    <div className="table-relative-fix">
      <div className="table-scroll">
        <Table
          total={meetingRecordDetail.totalRows}
          page={page}
          size={size}
          onChange={(size, page) => onChangePageSize(size, page)}
          className='mt-3'>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={100}>ตอนที่</Table.Column>
              <Table.Column minWidth={150}>ช่วงเวลา</Table.Column>
              <Table.Column minWidth={300}>ผู้จดรายงานการประชุม</Table.Column>
              <Table.Column minWidth={150}>สถานะ</Table.Column>
              <Table.Column minWidth={250}>ชื่อไฟล์ภาพ</Table.Column>
              <Table.Column minWidth={150}>เวลา</Table.Column>
              <Table.Column minWidth={200} className="fix-col" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {meetingRecordDetail.rows?.map((value) => (
              <Table.Row key={value.id}>
                <Table.Cell center>{value.chapter.toString()}</Table.Cell>
                <Table.Cell center>{`${getTime(value.startTime)} - ${getTime(value.endTime)}`}</Table.Cell>
                <Table.Cell>{value.name}</Table.Cell>
                <Table.Cell center>{<Status type={StatusType.CHAPTER_STATUS} value={value.status} />}</Table.Cell>
                <Table.Cell>{value.fileName}</Table.Cell>
                <Table.Cell center>
                  <>{getTime(value.startRecord)}{`${value.endRecord ? ' - ' : ''}`}{getTime(value.endRecord)}</>
                </Table.Cell>
                <Table.Cell center className="fix-col">
                  <div className="d-flex gap-2 justify-content-center">
                    <VideoBrowse
                      confirm={value.fileName ? true : false}
                      value={value.video}
                      onChange={(val) => onUploadAsync(value, val)}
                      disabled={!(user.isAdmin || user.isCommander) || value.status === StatusMeeting.RECORD} />
                    <Button
                      variant="outline-primary"
                      disabled={value.status === StatusMeeting.RECORD}
                      onClick={() => onRemoveAsync(value.id)}>
                      <FaTrashAlt />
                    </Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div >
  );
};

function StartRecord(props: RecordModalProps) {
  const { id } = useParams();

  const onHide = () => {
    props.onHide();
  };

  const onSubmitAsync = async () => {
    const res = await service.startRecordAsync(id!);

    if (res.status === HttpStatusCode.Accepted) {
      props.onSubmit();
    }

    if (res.status === HttpStatusCode.Conflict) {
      return toast.error('ไม่สามารถบันทึกได้ เนื่องจากเริ่มบันทึกแล้ว');
    }
  }

  return (
    <Modal show={props.show} title="บันทึกการประชุม">
      <Row className="mt-2">
        <Col className="mt-1">
          <h4>
            ต้องการที่บันทึกการประชุมใช่หรือไม่ ?
          </h4>
        </Col>
      </Row>
      <div className="button d-flex flex-row gap-3 justify-content-center mt-4">
        <Button
          variant="outline-primary"
          className="w-50"
          onClick={onHide}>
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          className="w-50"
          onClick={onSubmitAsync}>
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}

function PauseRecord(props: RecordModalProps) {
  const { id } = useParams();

  const onHide = () => {
    props.onHide();
  };

  const onSubmitAsync = async () => {
    const res = await service.pauseRecordAsync(id!);

    if (res.status === HttpStatusCode.Accepted) {
      props.onSubmit();
    }
  }

  return (
    <Modal show={props.show} title="หยุดการประชุมชั่วคราว">
      <Row className="mt-2">
        <Col className="mt-1">
          <h4>
            ต้องการที่จะหยุดการประชุมชั่วคราวใช่หรือไม่ ?
          </h4>
        </Col>
      </Row>
      <div className="button d-flex flex-row gap-3 justify-content-center mt-4">
        <Button
          variant="outline-primary"
          className="w-50"
          onClick={onHide}>
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          className="w-50"
          onClick={onSubmitAsync}>
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}

function StopRecord(props: RecordModalProps) {
  const { id } = useParams();

  const onHide = () => {
    props.onHide();
  };

  const onSubmitAsync = async () => {
    const res = await service.stopRecordAsync(id!);

    if (res.status === HttpStatusCode.Accepted) {
      props.onSubmit();
    }
  }

  return (
    <Modal show={props.show} title="จบการประชุม">
      <Row className="mt-2">
        <Col className="mt-1">
          <h4>
            ต้องการที่จะจบการประชุมใช่หรือไม่ ?
          </h4>
        </Col>
      </Row>
      <div className="button d-flex flex-row gap-3 justify-content-center mt-4">
        <Button
          variant="outline-primary"
          className="w-50"
          onClick={onHide}>
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          className="w-50"
          onClick={onSubmitAsync}>
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}

function StreamController() {
  const [streamData, setStreamData] = useState<StreamData>({} as StreamData);

  useEffect(() => {
    getStreamintDataAsync();

    const timer = setInterval(async () => {
      getStreamintDataAsync();
    }, 30000);

    return () => clearInterval(timer);
  }, []);

  const getStreamintDataAsync = async () => {
    const splitData: string[] = [];

    const data = await manageCacheVideoAsync();

    data?.split('\n').forEach((value, index) => {
      if (value && index !== 0) {
        splitData.push(value);
      }
    });

    setStreamData({
      ...streamData,
      streamState: splitData[0].split(' ')[2],
      status: splitData[1].split(' ')[1],
      bitrate: splitData[2].split(' ')[1],
      duration: splitData[3].split(' ')[1],
      cache: splitData[4].split(' ')[2],
    });
  };

  return (
    <Card className="mt-3">
      <label className="fs-4 text-primary">เครื่องบันทึก Blackmagic</label>
      <div className="d-flex justify-content-around">
        <div className="mt-3">
          <ul>
            <li className="text-danger fs-5 fw-bold">ถ่ายทอดสดแล้ว</li>
            <li className="mx-4 fs-2 fw-bold list-style-none">{streamData.duration}</li>
          </ul>
          <ul>
            <li className=" fs-5 fw-bold">แคช</li>
            <li className={`mx-4 fw-bold list-style-none fs-8 ${Number(streamData.cache) >= 10 ? 'text-danger' : ''}`}>{streamData.cache} %</li>
          </ul>
        </div>
        <div className="my-auto">
          <Button
            variant="outline-primary"
            className="w-100"
            onClick={startCacheVideoAsync}>
            <FaPlay /> เปิดเครื่อง
          </Button>
          <Button
            variant="outline-primary"
            className="w-100 my-3"
            onClick={stopCacheVideoAsync}>
            <FaPause /> ปิดเครื่อง
          </Button>
          <Button
            variant={Number(streamData.cache) >= 10 ? "outline-danger" : "outline-primary"}
            className="w-100"
            onClick={clearCacheVideoAsync}>
            <MdLayersClear size={22} /> ล้างแคช
          </Button>
        </div>
      </div>
    </Card>
  );
}