import { Card, Layout, Modal, Status, StatusType, Table, VideoBrowse } from "components";
import { CtMeetingRecordDetail, CtMeetingRecordDetailList, Pagination } from "models";
import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FaAngleLeft, FaPauseCircle, FaStopCircle, FaSyncAlt, FaTrashAlt } from "react-icons/fa";
import { SubmitFunction, useLoaderData, useNavigate, useParams, useSubmit } from "react-router-dom";
import { getFormatDateBC, getTime, showModalConfirmAsync } from "utils";
import { c0203 as service, c0204, recordService } from 'services';
import { HttpStatusCode } from "axios";
import toast from "utils/toast";
import { BsRecordCircle } from "react-icons/bs";
import { StatusMeeting } from "constant";
import Hls from "hls.js";
import { v4 as uuidv4 } from 'uuid';
import { useHls } from "hooks";

type Loader = { detailListData: Pagination<CtMeetingRecordDetailList>; headerDetail: CtMeetingRecordDetail; };

type CtMeetingRecordContext = {
  list: Pagination<CtMeetingRecordDetailList>;
  setList: Dispatch<SetStateAction<Pagination<CtMeetingRecordDetailList>>>;
  onChangePageSize: (size: number, page: number) => void;
  submit: SubmitFunction;
  page: number,
  size: number,
};

const Context = createContext({} as CtMeetingRecordContext);

interface RecordModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: () => void;
};

export default function C0203Detail() {
  const { detailListData } = useLoaderData() as Loader;
  const [list, setList] = useState<Pagination<CtMeetingRecordDetailList>>(detailListData);
  const [showModal, setShowModal] = useState<boolean>(false);
  const submit = useSubmit();
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const { id } = useParams();

  useEffect(() => {
    setList(detailListData);
  }, [detailListData]);

  const searchAsync = useCallback(async (page: number, size: number, id: string) => {
    const { data, status } = await service.getDetailListAsync(size, page, id);

    if (status === HttpStatusCode.Ok) {
      setList(data);
    }
  }, [page, size]);

  const onChangePageSize = useCallback((size: number, page: number) => {
    setPage(page);
    setSize(size);

    submit({
      page: page.toString(),
      size: size.toString(),
    });
  }, []);

  const contextValue = {
    list,
    setList,
    onChangePageSize,
    submit,
    showModal,
    setShowModal,
    size,
    page,
    setPage,
    setSize,
  };

  return (
    <Context.Provider value={contextValue}>
      <Layout title="บันทึกข้อมูลภาพและเสียงการประชุม" className="c0203-detail">
        <HeaderButton />
        <Row>
          <Col sm={12} xxl={4}>
            <div className="d-grid">
              <Detail />
              <Time />
            </div>
          </Col>
          <Col md={6}>
            <Video />
          </Col>
          <Col md={2}>
            <Controller />
          </Col>
        </Row>
        <DataTable
          onChange={() => searchAsync(page, size, id!)} />
      </Layout>
    </Context.Provider>
  );
};

function HeaderButton() {
  const navigate = useNavigate();

  const onBack = () => {
    navigate("/c0203");
  };

  return (
    <div className="my-3">
      <Button
        variant="outline-primary"
        type="button"
        onClick={onBack}>
        <FaAngleLeft className="me-2" />ย้อนกลับ
      </Button>
    </div>
  );
};

function Detail() {
  const { headerDetail } = useLoaderData() as Loader;

  return (
    <Card title="รายละเอียดการบันทึกสัญญาณภาพและเสียง">
      <div className="d-grid">
        <p className="mb-1">วันที่ประชุม<span className="mx-2">:</span>{getFormatDateBC(headerDetail.meetingDate)}</p>
        <p className="mb-1">เวลา<span className="mx-2">:</span>{`${getTime(headerDetail.startTime)} - ${getTime(headerDetail.endTime)}`}</p>
        <p className="mb-1">สำนัก<span className="mx-2">:</span>{headerDetail.bureauName}</p>
        <p className="mb-1">คณะกรรมาธิการ/อื่นๆ<span className="mx-2">:</span>{headerDetail.committeeName}</p>
        <p className="mb-1">คณะอนุกรรมาธิการ/คณะทำงาน<span className="mx-2">:</span>{headerDetail.subCommitteeName}</p>
        <p className="mb-1">ครั้งที่<span className="mx-2">:</span>{`${headerDetail.time}`}</p>
        <p className="mb-1">ปี<span className="mx-2">:</span>{`${headerDetail.year + 543}`}</p>
        <p className="mb-1">ณ ห้องประชุม<span className="mx-2">:</span>{headerDetail.meetingRoom}</p>
      </div>
    </Card>
  );
};

function Time() {
  const [dateCurrent, setDateState] = useState<Date>(new Date());

  useEffect(() => {
    const timer = setInterval(() => setDateState(new Date()), 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Card title="เวลาปัจจุบัน" className="mt-3 text-primary">
      <div className="time">
        {dateCurrent.toLocaleString('th-TH', {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        })}
      </div>
    </Card>
  );
};

function Video() {
  const { headerDetail } = useLoaderData() as Loader;
  const [streamingUrl, setStreamingUrl] = useState<string>();
  const streamingUrlInput = process.env.REACT_APP_STREAMING_URL_INPUT;
  const streamingUrlOutput = process.env.REACT_APP_STREAMING_URL_OUTPUT;
  const streamingName = uuidv4();
  const { hlsRef } = useHls();

  useEffect(() => {
    reStream(headerDetail?.streamUrl);
  }, [headerDetail?.streamUrl]);

  const reStream = async (url: string) => {
    if (!url) {
      toast.error('ไม่พบสัญญาณภาพ');

      return;
    }

    recordService.reStreamAsync(url, `${streamingUrlInput}/${streamingName}`);

    setStreamingUrl(`${streamingUrlOutput}/${streamingName}.m3u8`);
  };

  useEffect(() => {
    startHls();
  }, [streamingUrl]);

  const startHls = () => {
    if (!streamingUrl) {
      return;
    }

    const video = document.getElementById("video-streaming") as HTMLMediaElement;

    if (Hls.isSupported()) {
      const config = new Hls();
      hlsRef.current = config;

      config.loadSource(streamingUrl);
      config.attachMedia(video);

      config.on(Hls.Events.ERROR, (_, data) => {
        // for see error on console
        console.error(data);

        setTimeout(() => startHls(), 10000);
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = streamingUrl;
    }
  };

  return (
    <video
      id="video-streaming"
      autoPlay={true}
      className="w-100 h-100" />
  );
};

function Controller() {
  const { headerDetail } = useLoaderData() as Loader;
  const detailId = useParams().id as string;
  const delay = 30;
  const [recordButtonDelay, setRecordButtonDelay] = useState<number>();
  const [stopButtonDelay, setStopButtonDelay] = useState<number>();
  const [showStartModal, setShowStartModal] = useState<boolean>(false);
  const [showPauseModal, setShowPauseModal] = useState<boolean>(false);
  const [showStopModal, setShowStopModal] = useState<boolean>(false);
  const [currentStatus, setCurrentStatus] = useState<StatusMeeting>();

  useEffect(() => {
    setCurrentStatus(headerDetail.status as StatusMeeting);
  }, [headerDetail]);

  const onUploadFullAsync = async (id: string, file: File | Blob) => {
    await service.uploadFullVideoManualAsync(id!, file as File);

    toast.success("อัพโหลดวิดิโอสำเร็จ");
  };

  const countdownCanStop = () => {
    setStopButtonDelay(delay);

    setInterval(() => {
      setStopButtonDelay(val => {
        if (val && val >= 0) {
          return val - 1;
        }
      });
    }, 1000);
  };

  const countdownCanRecord = () => {
    setRecordButtonDelay(delay);

    setInterval(() => {
      setRecordButtonDelay(val => {
        if (val && val >= 0) {
          return val - 1;
        }
      });
    }, 1000);
  };

  const onStartSubmit = () => {
    setCurrentStatus(StatusMeeting.RECORD);

    toast.info("กำลังบันทึกข้อมูลภาพและเสียงการประชุม");

    setShowStartModal(false);

    countdownCanStop();
  };

  const onPauseSubmit = () => {
    setCurrentStatus(StatusMeeting.PAUSE_RECORD);

    toast.info("กำลังหยุดบันทึกข้อมูลภาพและเสียงการประชุมชั่วคราว");

    setShowPauseModal(false);

    countdownCanRecord();
  };

  const onStopSubmit = () => {
    setCurrentStatus(StatusMeeting.RECORDED);

    toast.info("จบการบันทึกข้อมูลภาพและเสียงการประชุม");

    setShowStopModal(false);
  };

  const disabledStart = useMemo(() => {
    return (currentStatus !== StatusMeeting.CONFIRM && currentStatus !== StatusMeeting.PAUSE_RECORD) ||
      (!!recordButtonDelay && recordButtonDelay >= 0);
  }, [currentStatus]);

  const disabledPauseAndStop = useMemo(() => {
    return (currentStatus === StatusMeeting.DRAFT || currentStatus === StatusMeeting.CONFIRM || currentStatus == StatusMeeting.RECORDED) ||
      (!!stopButtonDelay && stopButtonDelay >= 0);
  }, [currentStatus]);

  return (
    <>
      <div className="controller d-flex flex-sm-row flex-md-column gap-2 h-100">
        <Button
          variant="outline-primary"
          className="align-items-center"
          onClick={() => setShowStartModal(true)}
          disabled={disabledStart}>
          {recordButtonDelay && recordButtonDelay >= 0 ? recordButtonDelay : <><BsRecordCircle className="mt-2 fs-2" />บันทึก</>}
        </Button>
        <Button
          variant="outline-primary"
          className="align-items-center"
          onClick={() => setShowPauseModal(true)}
          disabled={disabledPauseAndStop}>
          {stopButtonDelay && stopButtonDelay >= 0 ? stopButtonDelay : <><FaPauseCircle className="mt-2 fs-2" />หยุดบันทึกชั่วคราว</>}
        </Button>
        <Button
          variant="outline-primary"
          className="align-items-center"
          onClick={() => setShowStopModal(true)}
          disabled={disabledPauseAndStop}>
          {stopButtonDelay && stopButtonDelay >= 0 ? stopButtonDelay : <><FaStopCircle className="mt-2 fs-2" />จบการประชุม</>}
        </Button>
        <VideoBrowse
          confirm={headerDetail.fileId ? true : false}
          className="pt-5 pb-5"
          onChange={(val) => onUploadFullAsync(detailId, val)}
          disabled={currentStatus === StatusMeeting.RECORD} />
      </div>
      <StartRecord
        onSubmit={onStartSubmit}
        onHide={() => setShowStartModal(false)}
        show={showStartModal} />
      <PauseRecord
        onSubmit={onPauseSubmit}
        onHide={() => setShowPauseModal(false)}
        show={showPauseModal} />
      <StopRecord
        onSubmit={onStopSubmit}
        onHide={() => setShowStopModal(false)}
        show={showStopModal} />
    </>
  );
};

function DataTable(props: { onChange: () => void }) {
  const { list, setList, onChangePageSize, submit, page, size } = useContext(Context);
  const detailId = useParams().id as string;
  const { id } = useParams();

  const onUploadAsync = async (data: CtMeetingRecordDetailList, file: File) => {
    data.video = file;
    await c0204.uploadDocumentAsync(detailId, data.id, file);

    submit({
      page: list.page.toString(),
      size: list.size.toString(),
    });

    toast.success("นำเข้าวิดีโอสำเร็จ");
  };

  const onRemoveAsync = async (rankId: string) => {
    if (await showModalConfirmAsync('ยืนยันการลบไฟล์')) {
      const { status } = await service.removeFileAsync(id!, rankId);

      if (status === HttpStatusCode.Accepted) {
        toast.success('ลบไฟล์สำเร็จ');

        props.onChange();
      }
    }
  };

  const onRefreshAsync = async () => {
    const res = await service.getDetailListAsync(size, page, id!);

    if (res.status == HttpStatusCode.Ok) {
      setList(res.data);
    }
  };

  return (
    <div className="table-relative-fix">
      <div className="d-flex justify-content-end mt-3">
        <Button
          variant="outline-primary"
          className="d-flex gap-2 align-items-center"
          onClick={onRefreshAsync}>
          <FaSyncAlt />รีเฟรซข้อมูลในตาราง
        </Button>
      </div>
      <div className="table-scroll">
        <Table
          total={list.totalRows}
          page={list.page}
          size={list.size}
          onChange={onChangePageSize}
          className='mt-3'>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={100}>ตอนที่</Table.Column>
              <Table.Column minWidth={200}>ช่วงเวลา</Table.Column>
              <Table.Column minWidth={300}>ผู้จดรายงานการประชุม</Table.Column>
              <Table.Column minWidth={150}>สถานะ</Table.Column>
              <Table.Column minWidth={250}>ชื่อไฟล์ภาพ</Table.Column>
              <Table.Column minWidth={200}>เวลา</Table.Column>
              <Table.Column minWidth={250} className="fix-col" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {list.rows?.map((value) => (
              <Table.Row key={value.id}>
                <Table.Cell center>{`${value.chapter}`}</Table.Cell>
                <Table.Cell center>{`${getTime(value.startTime)} - ${getTime(value.endTime)}`}</Table.Cell>
                <Table.Cell>{value.name}</Table.Cell>
                <Table.Cell center>{<Status type={StatusType.CHAPTER_STATUS} value={value.status} />}</Table.Cell>
                <Table.Cell>{value.fileName}</Table.Cell>
                <Table.Cell center>
                  <>{getTime(value.startRecord)}{`${value.endRecord ? ' - ' : ''}`}{getTime(value.endRecord)}</>
                </Table.Cell>
                <Table.Cell center className="fix-col">
                  <div className="d-flex gap-2 justify-content-center">
                    <VideoBrowse
                      confirm={value.fileName ? true : false}
                      value={value.video}
                      onChange={(val) => onUploadAsync(value, val as File)}
                      disabled={value.status === StatusMeeting.RECORD} />
                    <Button
                      variant="outline-primary"
                      disabled={value.status === StatusMeeting.RECORD}
                      onClick={() => onRemoveAsync(value.id)}>
                      <FaTrashAlt />
                    </Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

function StartRecord(props: RecordModalProps) {
  const { id } = useParams();

  const onHide = () => {
    props.onHide();
  };

  const onSubmitAsync = async () => {
    const res = await service.startRecordAsync(id!);

    if (res.status === HttpStatusCode.Accepted) {
      props.onSubmit();
    }
  }

  return (
    <Modal show={props.show} title="บันทึกการประชุม">
      <Row className="mt-2">
        <Col className="mt-1">
          <h4>
            ต้องการที่บันทึกการประชุมใช่หรือไม่ ?
          </h4>
        </Col>
      </Row>
      <div className="button d-flex flex-row gap-3 justify-content-center mt-4">
        <Button
          variant="outline-primary"
          className="w-50"
          onClick={onHide}>
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          className="w-50"
          onClick={onSubmitAsync}>
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}

function PauseRecord(props: RecordModalProps) {
  const { id } = useParams();

  const onHide = () => {
    props.onHide();
  };

  const onSubmitAsync = async () => {
    const res = await service.pauseRecordAsync(id!);

    if (res.status === HttpStatusCode.Accepted) {
      props.onSubmit();
      props.onHide();
    }
  }

  return (
    <Modal show={props.show} title="หยุดการประชุมชั่วคราว">
      <Row className="mt-2">
        <Col className="mt-1">
          <h4>
            ต้องการที่จะหยุดการประชุมชั่วคราวใช่หรือไม่ ?
          </h4>
        </Col>
      </Row>
      <div className="button d-flex flex-row gap-3 justify-content-center mt-4">
        <Button
          variant="outline-primary"
          className="w-50"
          onClick={onHide}>
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          className="w-50"
          onClick={onSubmitAsync}>
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}

function StopRecord(props: RecordModalProps) {
  const { id } = useParams();

  const onHide = () => {
    props.onHide();
  };

  const onSubmitAsync = async () => {
    const res = await service.stopRecordAsync(id!);

    if (res.status === HttpStatusCode.Accepted) {
      props.onSubmit();
      props.onHide();
    }
  }

  return (
    <Modal show={props.show} title="จบการประชุม">
      <Row className="mt-2">
        <Col className="mt-1">
          <h4>
            ต้องการที่จะจบการประชุมใช่หรือไม่ ?
          </h4>
        </Col>
      </Row>
      <div className="button d-flex flex-row gap-3 justify-content-center mt-4">
        <Button
          variant="outline-primary"
          className="w-50"
          onClick={onHide}>
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          className="w-50"
          onClick={onSubmitAsync}>
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
};